<template>
  <div class="position-relative" v-click-outside="hideDropdown">
    <v-text-field
      v-model="value"
      :label="label"
      :disabled="disabled"
      outlined
      dense
      hide-details
      @focus="showDropdown"
      @keyup="getSuggestion"
      class="c-input-small"
      :placeholder="$t('labels.barcode_sku_name_description_size')"
    ></v-text-field>
    <div class="position-absolute result-list" v-if="dropdown">
      <template v-if="suggestions.length > 0">
        <div
          class="result-item"
          :class="{ 'selected-item': selectedIds.includes(suggestion.id) }"
          v-for="suggestion in suggestions"
          :key="`s_${suggestion.id}`"
          @click="selectItem(suggestion)"
        >
          <div class="d-flex align-center">
            <div class="mr-2" style="min-width: 40px; min-height: 40px">
              <ImageViewer
                v-if="suggestion.url_images"
                :url="suggestion.url_images"
                width="40px"
                height="40px"
              />
            </div>
            <div>
              Barcode: {{ suggestion.customer_goods_barcode }} | SKU:
              {{ suggestion.sku }} | Hàng hóa: {{ suggestion.name }} | Size:
              {{ suggestion.size }} | Khả dụng: {{ suggestion.available }} | Giá
              lẻ: {{ formatNumber(suggestion.price) }}
            </div>
          </div>
        </div>
        <div class="pt-3">
          <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="6"
          ></v-pagination>
        </div>
      </template>
      <template v-else>
        <div class="result-item font-italic">
          <div>
            {{
              !value ? $t("labels.enter_barcode_sku_name_description_size") : ""
            }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "SelectSuggestGoods",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    fromId: {
      type: [String, Number],
      value: () => null,
    },
    fromType: {
      type: [String, Number],
      value: () => null,
    },
    label: {
      type: String,
      value: () => null,
    },
    suggestionApiUrl: {
      type: String,
      value: () => null,
    },
    defaultValue: {
      type: String,
      value: () => null,
    },
    disabled: {
      type: Boolean,
      value: () => false,
    },
  },
  data: () => ({
    value: null,
    dropdown: false,
    selected: [],
    newItem: {},
    suggestions: [],
    page: 1,
    totalPage: 1,
  }),
  computed: {
    selectedIds() {
      return [...this.selected].map((s) => s.id);
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onChange() {},
    getSuggestion: debounce(function () {
      httpClient
        .post("/transfer-goods-list", {
          keywords: this.value,
          from_type: this.fromType,
          from_id: this.fromId,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.suggestions = [...data.rows];
        });
    }, 500),
    selectItem(item) {
      if (!this.selectedIds.includes(item.id)) {
        this.selected.push(item);
      } else {
        this.selected = [...this.selected].filter((s) => s.id !== item.id);
      }
      this.$emit("onSelect", this.selected);
    },
    showDropdown() {
      this.dropdown = true;
    },
    hideDropdown() {
      this.dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-list {
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  max-height: 280px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;

  .result-item {
    padding: 8px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;

    &:hover {
      background: #efefef;
      cursor: pointer;
    }

    &.selected-item {
      background-color: #eff6fd;
      color: #000000;
      font-weight: 600;
    }
  }
}
</style>
